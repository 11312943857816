import React from "react"
import { Link } from "gatsby"

import Layout, { Wrapper, Spacer } from "../components/layout"
import SEO from "../components/seo"

const Feedback = () => (
  <Layout>
    <SEO title="Feedback" />
    <Spacer h="5" />
    <Wrapper>
      <h1>Feedback</h1>
      <p>Welcome to feedback page</p>
      <Link to="/">Go back to Overview</Link>
    </Wrapper>
  </Layout>
)

export default Feedback
